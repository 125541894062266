html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

.App {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100vh;
}

.company-logo {
  height: 70px;
  width: 70px;
  background-color: #61D2A7;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.company-logo img{
  height: 25%;
  width: 70%;
}

.left-section {
  width: 50%;
  background-color: black;
  color: white;
}

.left-content{
  margin-left: 75px;
  margin-top: 50px;
  margin-right: 75px;
}

.right-section {
  width: 50%;
  background-color: #61D2A7;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-view {
  background-color: #61D2A7;
  border-radius: 25px;
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  display:flex;
  flex-direction: column;
}

.detail-view {
  background-color: #61D2A7;
  display:flex;
}

.linkedin-logo{
  text-align: end;
}

.linkedin-logo img{
  height: 25px;
  width: 25px;
  margin-right: 20px;
}

.image-placeholder {
  width: 150px;
  height: 150px;
  background-color: #ddd;
  border-radius: 20px;
  padding: 3px;
  margin-right: 15px;
}

.image-placeholder img{
  height: 100%;
  width: 100%;
  border-radius: 20px;
}

.first-content{
  text-align: center;
  margin-top: 15%;
  margin-bottom: 15%;
}

h1{
  font-size: 2.5rem;
  color: #ffffff;
  margin-bottom: 10px;
  margin-top: 5px;
}

.first-content-a{
  font-style: italic;
}

.sub-title{
  font-size: 1.5rem;
  color: #ffffff;
  margin-top: 0px;
}

h2{
  font-size: 1.5rem;
  font-weight: 600;
  color: #000;
  margin-bottom: 10px;
}

.second-sub-title{
  font-size: 1.3rem;
  font-weight: 400;
  color: #000;
  margin-top: 0px;
  margin-bottom: 15px;
}

.services{
  display: flex;
  flex-direction: row;
  font-size: 0.9rem;
  font-weight: 300;
  color: #000;
  margin-top: 0px;
}

ul{
  padding: 0%;
  margin: 15px;
  margin-top: 0%;
  line-height: 1.6;
}

.cycle-div {
  background-color: #000;
  height: 450px;
  width: 450px;
  text-align: center;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.right-content{
  margin-top: 42px;
  margin-bottom: 20px;
  font-size: 2rem;
  color: #61D2A7;;
}

.qr-code {
  height: 264px;
  width: 264px;
  background-color: #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15%;

}

.qr-code img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 15%;
}

@media (max-width: 1024px) {
  .App {
    flex-direction: column;
  }

  .left-section, .right-section {
    width: 100%;
    height: 100%;
  }

  .cycle-div {
    height: 300px;
    width: 300px;
  }

  .right-content {
    font-size: 1.5rem;
  }

  .qr-code {
    height: 200px;
    width: 200px;
  }
}

/* For mobile devices like iPhone 6/7/8/SE 2020 */
@media only screen and (max-width: 428px) {

  .App {
    flex-direction: column;
  }

  .left-section, .right-section {
    width: 100%;
  }
  
  .company-logo {
    height: 50px;
    width: 50px;
    background-color: #61D2A7;
    border-radius: 50%;
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .left-content{
    margin-left: 25px;
    margin-top: 50px;
    margin-right: 25px;
  }
  
  .company-logo img {
    max-width: 100%;
    max-height: 100%;
  }

  .first-content{
    text-align: center;
    margin-top: 50px;
    margin-bottom: 60px;
  }

  h1{
    font-size: 1.3rem;
    color: #ffffff;
    margin-bottom: 10px;
    margin-top: 5px;
  }
  
  .first-content-a{
    font-style: italic;
  }

  .card-view {
    background-color: #61D2A7;
    padding: 20px;
    margin-top: 20px;
    border-radius: 25px;
    width: 90%;
  }

  .sub-title{
    font-size: 1rem;
    color: #ffffff;
    margin-top: 0px;
  }

  .image-placeholder {
    width: 90px;
    height: 90px;
    background-color: #ddd;
    border-radius: 15px;
    padding: 2px;
    margin-right: 15px;
  }

  .image-placeholder img{
    border-radius: 15px;
  }
  
  h2{
    font-size: 1rem;
    font-weight: 600;
    color: #000;
    margin-bottom: 5px;
  }

  .second-sub-title{
    font-size: 0.9rem;
    font-weight: 400;
    color: #000;
    margin-top: 0px;
    margin-bottom: 5px;
  }

  .services{
    font-size: 0.6rem;
    font-weight: 300;
    color: #000;
    margin-top: 0px;
  }

  ul{
    margin-right: 5px;
    margin-bottom: 10px;
    margin-left: 15px;
  }

  .linkedin-logo{
    align-items: end;
    text-align: right;
  }

  .linkedin-logo img{
    margin-right: 5px;
  }

  .cycle-div {
    margin-top: 25px;
    margin-bottom: 25px;
    background-color: #000;
    height: 250px;
    width: 250px;
    text-align: center;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .right-content{
    margin-top: 32px;
    margin-bottom: 10px;
    font-size: 1.5rem;
  }
  
  .qr-code {
    height: 145px;
    width: 145px;
    border-radius: 15px;
  }
  
}

/* For Android devices with 5 inch screen */
@media only screen and (max-width: 360px) {
  /* Add your CSS rules here */
}
